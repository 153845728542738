<template>
  <div id="shareEwm">
    <div class="containEwm">
      <div class="zbEwm" style="margin-right: 130px">
        <div class="name">直播间二维码</div>
        <div class="tu" id="qrcode"></div>
        <el-button type="text" class="download" @click="downEwm">
          下载二维码
        </el-button>
      </div>
      <div class="zbEwm">
        <div class="name">直播间小程序码</div>
        <div class="tu">
          <img :src="shareinfoval.small_url" alt="" />
        </div>
        <el-button type="text" class="download" @click="prompt">
          <a
            download="xcx.jpg"
            style="text-decoration: none; color: #0aa29b"
            :href="shareinfoval.small_url"
          >
            下载二维码
          </a>
        </el-button>
      </div>
    </div>
    <div class="link">直播间链接</div>
    <div class="linkcontain">
      {{ shareinfoval.share_url }}
    </div>
    <div class="openlink">
      <el-button
        type="text"
        style="margin-right: 48px"
        class="for-copy"
        :data-clipboard-text="shareinfoval.share_url"
      >
        复制链接
      </el-button>
      <el-button
        type="text"
        @click="$store.dispatch('open', shareinfoval.share_url)"
      >
        打开页面
      </el-button>
    </div>
  </div>
</template>


<script>
import ClipboardJS from 'clipboard'
import QRCode from '@/assets/js/qrcode'
export default {
  name: 'windows-live-share',
  data() {
    return {
      shareinfoval: {},
    }
  },

  mounted() {
    const option = {}
    if (this.$route.query.course_type == 1) {
      option.url = '/Share/posterInvitation'
      option.correlation_id = this.$route.query.id
    } else {
      option.url = '/openClass/openClassHeader'
      option.open_class_id = this.$route.query.id
    }

    this.$http({
      url: option.url,
      data: option,
      callback: ({ data }) => {
        this.shareinfoval = {
          share_url: data.share_url || data.url,
          small_url: data.small_url || data.small,
        }
        this.create_ewm()
        this.initCopy()
      },
    })
  },

  methods: {
    //复制链接
    initCopy() {
      const self = this
      let clipboard = new ClipboardJS('.for-copy')
      clipboard.on('success', function () {
        self.$root.prompt({
          msg: '复制成功',
          type: 'success',
        })
      })
      clipboard.on('error', function () {
        self.$root.prompt('复制失败')
      })
    },

    // 生成二维码
    create_ewm() {
      const size = 155
      //生成前先清空原有的，不然会有多张二维码的情况
      document.querySelector('#qrcode').innerHTML = ''
      const node = document.getElementById('qrcode')
      if (node) {
        this.qrcode = new QRCode(node, {
          text: this.shareinfoval.share_url, //地址
          width: size,
          height: size,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
        })
      }
    },

    prompt() {
      this.$root.prompt({
        msg: '已下载，请稍后在电脑桌面查看，如没有文件请重试下载',
        type: 'success',
      })
    },

    // 下载二维码
    downEwm() {
      this.prompt()
      const imgUrl = this.qrcode.getBase64Code()
      if (window.navigator.msSaveOrOpenBlob) {
        var bstr = atob(imgUrl.split(',')[1])
        var n = bstr.length
        var u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        var blob = new Blob([u8arr])
        window.navigator.msSaveOrOpenBlob(blob, 'chart-download' + '.' + 'png')
      } else {
        // 这里就按照chrome等新版浏览器来处理
        const a = document.createElement('a')
        a.href = imgUrl
        a.setAttribute('download', 'chart-download')
        a.click()
      }
    },
  },
}
</script>

 <style lang="scss" scoped>
#shareEwm {
  width: 700px;
  height: 460px;
  position: relative;
  margin: 0 auto;
  font-size: 14px;
  color: #666666;
  line-height: 22px;
  padding: 20px 0;
  box-sizing: border-box;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
  }
  .containEwm {
    display: flex;
    justify-content: center;
    .zbEwm {
      display: flex;
      flex-direction: column;
      .name {
        text-align: center;
        font-size: 14px;
        color: #666666;
        line-height: 14px;
      }
      .tu {
        margin: 20px 0 10px 0;
        width: 155px;
        height: 155px;
      }
      .download {
        margin: 0 auto;
      }
    }
  }
  .link {
    text-align: center;
    margin: 20px 0;
    font-size: 14px;
    color: #666666;
    line-height: 14px;
  }
  .linkcontain {
    margin: 0 auto;
    font-size: 15px;
    color: #666666;
    line-height: 20px;
    padding: 20px;
    width: 609px;
    height: 56px;
    background: #f5f5f5;
    border-radius: 4px;
    border: 1px solid #d3d3d3;
  }
  .openlink {
    display: flex;
    justify-content: center;
  }
}
</style>